* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#navbar .navbar {
    position: relative;
    width: 100%;
    background-color: #000;
    padding: 10px 0;
}

#navbar ul.navbar-nav {
    margin: 0 auto;
}

#navbar ul.navbar-nav a {
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 16px;
    padding: 0 30px 0 0;
}

#navbar ul.navbar-nav a:hover {
    color: #EBDF1B;
    ;
}

#navbar .navbar img {
    width: 130px;
}

.yellow-btn {
    color: #000;
    background-color: #EBDF1B;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;
    padding: 11px 28px;
    border-radius: 25px;
}

.yellow-btn:hover {
    background-color: #fff;
    color: #000;
}

.yellow-btn .home-icon {
    padding-left: 8px;
}

.navbar-top {
    background-color: #EBDF1B;
    padding: 5px 0;
}

.navbar-top svg {
    font-size: 18px;
}

.navbar-top .navbar-top-link {
    display: flex;
    color: #000;
    text-decoration: none;
    font-size: 13px;
    font-weight: 500;
    padding-right: 25px;
}

.navbar-top .navbar-top-link p {
    padding-left: 4px;
}

.navbar-top .topcontactalllinks {
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar-top .alltopcontactalllinks {
    display: flex;
    justify-content: space-between;
}

.navbar-top .topcontactsociallinks {
    gap: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

div#navbar .dropdown-item {
    font-size: 14px !important;
    color: #000 !important;
}

#navbar .dropdown {
    position: relative;
    display: inline-block;
}

#navbar .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

#navbar .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    padding-left: 15px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

#navbar .dropdown-content li {
    list-style: none;
}

#navbar .dropdown-content {
    padding-left: 0;
}

#navbar .dropdown-content a:hover {
    background-color: #ddd;
}

#navbar .dropdown:hover .dropdown-content {
    display: block;
}

#navbar .dropdown:hover .dropbtn {
    background-color: #3e8e41;
}

@media only screen and (max-width: 991px) {
    #navbar ul.navbar-nav {
        background: #fff;
        border-radius: 5px;
        opacity: 1;
        padding: 20px;
        position: relative;
        z-index: 999;
    }

    #navbar ul.navbar-nav a {
        color: #000;
    }

    #navbar ul.navbar-nav li {
        padding-bottom: 10px;
    }
}