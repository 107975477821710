#about-page .text-desc {
    color: #000;
}

#about-page .logo-group {
    padding: 0PX 0;
}

#about-page #testimonial .item .text-desc {
    color: #fff;
}

#about-page #testimonial {
    margin: 0px 0 50px 0;
}

#about-page .main-head {
    margin-bottom: 10px;
}
#about-page #all-banner {
    background-image: url("../../Assests/img/about-banner.webp");
}


/* about-inner start */
#about-inner {
    padding: 60px 0;
}

#about-inner img {
    width: 350px;
    display: flex;
    margin: 0 auto;
}

#about-inner .main-head {
    font-size: 33px;
    font-weight: 600;
    margin-bottom: 10px;
}

#about-inner .text-desc {
    color: #000;
    font-size: 17px;
    margin-bottom: 15px;
}

#about-inner .about-inner-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#about-inner .about-inner-text button:hover {
    background-color: #000;
    color: #fff;
}

/* about-inner end */
/* --------------------- */
/* mission start */
#mission {
    padding-top: 60px;
}

#mission .text-desc {
    font-size: 22px;
    font-weight: 600;
}

#mission img {
    width: 90%;
}

#mission .mission-text {
    padding-left: 20px;
    padding-right: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#mission .main-head {
    margin-bottom: 15px;
    font-weight: 600;
}

#mission .values-list p {
    padding-bottom: 12px;
}

/* Promises start */
#Promises {
    background-color: #000;
    padding: 60px 0;
    margin-top: 60px;
}

#Promises img {
    width: 80%;
}

#Promises .main-head {
    color: #EBDF1B;
    font-weight: 600;
}

#Promises .text-desc {
    color: #fff;
}

#Promises ul {
    padding-left: 0;
    margin-top: 15px;
}

#Promises ul li {
    display: flex;
    list-style: none;
    align-items: center;
    padding-bottom: 12px;
}

#Promises ul li svg {
    color: #EBDF1B;
    font-size: 22px;
}

#Promises ul li .text-desc {
    font-size: 17px;
    padding-left: 11px;
}

#Promises .promise-list {
    align-items: start;
}

/* testimonial start */
#about-page #testimonial .main-head {
    margin-bottom: 20px;
    font-weight: 600;
}

#about-page #testimonial {
    padding-top: 80px;
}

/* Team start */
#team .main-head {
    font-weight: 600;
    padding-bottom: 20px;
}

#team .team-box {
    margin-bottom: 40px;
    width: 48%;
    border-radius: 20px;
    padding: 0;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

#team .team-box .col-md-5 {
    padding: 0;
}

#team .team-box .col-md-7 {
    display: flex;
    align-items: center;
    padding: 19px;

}

#team .team-box .team-desc h4 {
    font-weight: 600;
    font-size: 26px;
    margin-bottom: 5px;
}

#team .team-box .team-desc h6 {
    font-weight: 600;
    margin-bottom: 15px;
    font-size: 15px;
}

#team .team-box .team-desc .text-desc {
    font-weight: 600;
    font-size: 15px;
}

#team .team-row {
    padding-bottom: 50px;
}

#team .team-row {
    display: flex;
    justify-content: space-between;
}

#team .team-box img {
    /* height: 470px; */
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}

/* scheduleCall start */
#scheduleCall {
    padding: 60px 0;
    background-color: #EBDF1B;
}

#scheduleCall .main-head {
    font-size: 28px;
    font-weight: 400;
}

#scheduleCall .row {
    align-items: center;
}

#scheduleCall .row img {
    width: 90%;
}

/* WorkplaceWellness start */
#about-page #WorkplaceWellness {
    padding: 60px 0 0 0;
}

#common-blogs {
    padding: 60px 0;
}

#common-blogs .main-head {
    font-weight: 600;
    padding-bottom: 20px;
}

#common-blogs .blogs-inner {
    border: 1px solid #ccc;
    -webkit-box-shadow: 9.899px 9.899px 30px 0 rgba(0, 0, 0, .1);
    box-shadow: 9.899px 9.899px 30px 0 rgba(0, 0, 0, .1);
    background-color: #fff;
    border-radius: 4px;
    height: 570px;
}

#common-blogs .blogs-inner img {
    height: 250px;
    object-fit: cover;
}

#common-blogs .blogs-inner h3 {
    font-size: 18px;
    color: #EBDF1B;
}

#common-blogs .blogs-inner .main-head {
    font-size: 22px;
    padding-bottom: 0px;
}

#common-blogs .blogs-inner p {
    font-size: 17px;
}

.blog-desc {
    padding: 20px;
}
.blog-box{

}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 768px) {
    #about-inner .about-inner-text {
        padding-top: 40px;
    }

    #about-inner {
        padding: 30px 0;
    }

    #mission .mission-text {
        padding-top: 20px;
        padding-right: 0px;
    }

    #mission {
        padding-top: 30px;
    }

    #mission img {
        width: 100%;
    }

    #Promises .col-md-4 {
        display: flex;
        justify-content: center;
    }

    #Promises img {
        width: 80%;
    }

    #Promises ul li {
        align-items: flex-start;
    }

    #team .team-box {
        width: 100%;
        margin-bottom: 40px;
    }

    #team {
        padding: 20px;
    }

    #team .team-row {
        padding-bottom: 0px;
    }

    #scheduleCall .row img {
        width: 60%;
        padding-bottom: 30px;
    }

    #scheduleCall .main-head {
        padding-bottom: 12px;
        font-size: 28px !important;
    }

    #contact-form .main-head {
        padding-bottom: 13px;
    }

    #common-blogs .blogs-inner {
        margin-bottom: 25px;
    }

    #common-blogs {
        padding-bottom: 30px;
    }

    #common-blogs .main-head {
        font-size: 35px;
    }

    #common-blogs .blogs-inner .main-head {
        font-size: 22px !important;
    }
}

@media only screen and (min-width: 768px) and (max-width:992px) {
    #about-inner .about-inner-text {
        padding: 40px 0 0 0;
    }

    #mission .mission-text {
        padding-top: 0px;
    }

    #mission .text-desc {
        font-size: 20px;
    }

    #Promises img {
        object-fit: contain;
    }

    #common-blogs .blogs-inner img {
        height: 250px;
    }

    #scheduleCall .row img {
        width: 100%;
    }

    #scheduleCall .black-btn {
        margin: 0 auto;
        display: flex;
    }

    #scheduleCall .main-head {
        font-size: 26px !important;
    }

    #about-inner img {
        margin: 0;
    }
}