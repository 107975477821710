.item {
    background-color: #000;
    padding: 20px 30px;
    text-align: center;
    border-radius: 30px;
}

#testimonial .item h3 {
    color: #EBDF1B;
    font-size: 22px;
    font-weight: 500;
}

#testimonial .item svg {
    color: #fff;
    font-size: 66px;
}

#testimonial .item .text-desc {
    padding: 23px 0;
    font-size: 17px;
    font-weight: 300;
    line-height: 1.7rem;
}

#testimonial .owl-dots {
    padding-top: 30px;
}

#testimonial.owl-dot span {
    background: #fff;
}

#testimonial .owl-dot.active span {
    background-color: #000;
}

#testimonial .item {
    height: 419px;
}