

.services .services-box {
    background-image: url("../../../Assests/img/services-1.webp");
    height: 360px;
    object-fit: cover;
    width: 117%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: -50px;
    padding: 45px 20px 45px 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.services .services-box .h4-head {
    margin-bottom: 0px;
    font-size: 33px;
    padding-bottom: 20px;
    font-weight: 400;
    color: #fff;
}

.services .out-border {
    border: 7px solid #EBDF1B;
    padding: 17px;
    margin-left: 30px;
}

.services .services-box a {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding-bottom: 10px;
}

.services .services-box a svg {
    color: #EBDF1B;
    width: 26px;
    font-size: 20px;
    display: flex;
    justify-content: flex-start;
}

.services .services-box a p {
    font-size: 17px;
}

.services .services-box .navbar-top-link-top svg {
    margin-top: -21px;
}

div#hover-btn {
    margin-top: 20px;
}

#hover-btn .button {
    background-color: #fff;
    color: white;
    text-decoration: none;
    border-radius: 60px;
    height: 42px;
    display: inline-flex;
    align-items: center;
    overflow: hidden;
    width: auto;
    max-width: 42px;
    /** I'm animating max-width because width needs to be auto, and auto can't be animated **/
    -webkit-transition: max-width 0.3s;
    transition: max-width 0.3s;
    /* max-width: 300px; */
}


#hover-btn .icon .hover-icon svg {
    color: #000;
    font-size: 24px;
}

#hover-btn .button:hover {
    max-width: 300px;
    flex-direction: row-reverse;
    padding-left: 20px;
    background-color: #EBDF1B;
}

#hover-btn .icon {
    font-family: "Font Awesome 5 Free";
    font-size: 16px;
    margin-right: 15px;
    padding: 0px 8px;
    display: flex;
    align-items: center;
}

#hover-btn .text {
    white-space: nowrap;
    color: #000;
    font-family: "Lexend Deca", Sans-serif;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.4px;
}

div#hover-btn a {
    padding-bottom: 0;
}