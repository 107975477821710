#footer {
    /* position: absolute;
    bottom: 0; */
    background-image: url("../../../Assests/img/footer.webp");
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
}

#footer .footer-logo img {
    width: 200px;
    margin-top: -100px;
}

#footer .footer-logo p {
    font-weight: 300;
    font-size: 14px;
    padding-top: 20px;
    text-align: left;
}

#footer .footer-logo .footer-social-icon {
    display: flex;
    justify-content: space-between;
    padding-top: 57px;
    padding-right: 25px;
}

#footer .footer-logo .footer-social-icon i svg {
    font-size: 22px;
    color: #fff;
}

#footer .footer-list {
    padding-top: 50px;
}

#footer .footer-list h3 {
    font-size: 26px;
    font-weight: 400;
    text-transform: capitalize;
}

#footer .footer-list li {
    list-style: none;
    padding-bottom: 12px;
}

#footer .footer-list li a {
    text-decoration: none;
    color: #fff;
    font-weight: 300;
    font-size: 13px;
}

#footer .footer-list li a:hover {
    color: #EBDF1B;
}

#footer .footer-list ul {
    padding-left: 0;
    padding-top: 15px;
}

#footer .office-list ul li a {
    display: flex;
    align-items: center;
}

#footer .office-list ul li a svg {
    font-size: 20px;
    color: #EBDF1B;
}

#footer .office-list ul li p {
    font-size: 14px;
    padding-left: 10px;
}

#footer .office-list {
    padding-left: 20px;
}

#footer .office-list ul li {
    padding-bottom: 20px;
}

#footer .responsive-map iframe {
    height: 250px;
    width: 100%;
}

#footer .footerborder-bottom {
    border-bottom: 0.008px solid #242424;
    padding-bottom: 14px;
}

#footer .copyright {
    padding: 10px 0;
    text-align: center;
    text-transform: capitalize;
    font-weight: 400;
}

#footer .copyright a {
    color: #fff;
}