/* .accordion-button::after {
    background-image: url("../../../Assests/img/before-border.png");
  }
  .accordion-button:not(.collapsed)::after {
    background-image: url("../../../Assests/img/logo.png");
  } */
  .faq {
    padding: 60px 0;
}
.faq .h4-head {
    color: #000;
}

.faq button.accordion-button,
.faq .accordion-body {
    background-color: #F5F5F5;

}

.faq button.accordion-button {
    border-radius: 5px;
    padding: 22px 20px;
}

.faq .accordion-header h3 {
    color: #000;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 0;
}

.faq .accordion-body {
    border-radius: 0px 0px 5px 5px;
    padding-top: 0;
}

.faq .accordion-body p {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.4rem;
    text-align: justify;
    padding-right: 90px;
}

.faq .accordion-item {
    /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
    margin-bottom: 20px;
    border: none;
}

.faq .accordion-button:not(.collapsed) {
    border-radius: 5px 5px 0 0;
    padding-bottom: 7px;
}

.faq button.accordion-button,
.faq .accordion-item:first-of-type>.accordion-header .faq .accordion-button {

    border: none !important;
    box-shadow: none;
}

.faq button.accordion-button:focus {
    box-shadow: none;
}
